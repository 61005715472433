<template lang="">
    <div class="container">
        <div class="mb-3">
            <label class="form-label">إسم اللون</label>
            <input class="form-control" type="text" v-model="name" />
        </div>

        <div class="mb-3">
            <label class="form-label">قيمة اللون</label>
            <input type="color" v-model="color_value" id="colorPicker"/>
        </div>


        <div class="mb-3">
            <button class="btn btn-dark" type="button" @click="saveColor">حفظ التصنيف</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name:'new-color',
    data(){
        return {
            name:'',
            color_value:''
        }
    },


    methods: {
       async saveColor(){
        try {
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('color_value', this.color_value);

            await axios.post('https://boutiquette-store.ly/add-color', formData);

            this.name = ''
            this.color_value = ''
            this.$router.push('/home');
            
        } catch (error) {
            null
        }
       },





      
    }

}
</script>
<style lang="">
    
</style>